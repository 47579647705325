<template>
  <a-card :bordered="false" class="card-area">

    <common-table
      ref="table"
      path="log"
      :date-range="true"
      :columns="columns">

      <template slot="search">
        <a-button @click="exportExcel">导出Excel</a-button>
      </template>

      <template slot="time" slot-scope="{record}">
        <a-tag v-if="record.time<500" color="green">{{record.time}} ms</a-tag>
        <a-tag v-else-if="record.time<1000" color="cyan">{{record.time}} ms</a-tag>
        <a-tag v-else-if="record.time<1500" color="orange">{{record.time}} ms</a-tag>
        <a-tag v-else color="red">{{record.time}} ms</a-tag>
      </template>

      <template slot="operation" slot-scope="{record}">
        <action-delete v-hasPermission="['log:delete']" @click="remove(record)"></action-delete>
      </template>

    </common-table>

  </a-card>
</template>

<script>

  export default {

    data () {
      return {}
    },
    computed: {
      columns () {

        return [{
          title: '操作人',
          dataIndex: 'username'
        }, {
          title: '操作描述',
          dataIndex: 'operation'
        }, {
          title: '耗时',
          dataIndex: 'time',
          scopedSlots: {customRender: 'time'},
          sorter: true,
        },
          //   {
          //   title: '执行方法',
          //   dataIndex: 'method',
          // },
          //   {
          //   title: '方法参数',
          //   dataIndex: 'params',
          // },
          {
            title: 'IP地址',
            dataIndex: 'ip'
          }, {
            title: '操作地点',
            dataIndex: 'location'
          }, {
            title: '操作时间',
            dataIndex: 'createdAt',
            sorter: true,
          }, {
            title: '操作',
            scopedSlots: {customRender: 'operation'},
          }

        ]
      }
    },

    methods: {

      remove (record) {
        let that = this
        this.$confirm({
          title: '确定删除该记录?',
          content: '当您点击确定按钮后，该记录将会被彻底删除',
          centered: true,
          onOk () {
            that.$delete('log/' + record.id).then(() => {
              that.$message.success('删除成功')
              that.fetch()
            })
          }
        })
      },
      exportExcel () {
        this.$refs.table.export("系统日志")
      },

      fetch () {
        this.$refs.table.getData()
      }
    }
  }
</script>
<style lang="less" scoped>

</style>
